import React, { FC } from 'react'
import { Divider as AntDivider } from 'antd/lib'
import { DividerProps as AntDividerProps } from 'antd/lib/divider'

import './divider.less'

export type DividerProps = AntDividerProps & {
    // Custom Props definition
}

export const Divider: FC<DividerProps> = ({ className, ...rest }) => {
    return (
        <AntDivider 
            className={`${className || ''} pyxis-divider`} 
            {...rest} 
        />
    )
}