import React, { FC } from 'react'
import { Affix as AntAffix } from 'antd/lib'
import { AffixProps as AntAffixProps } from 'antd/lib/affix'

import './affix.less'

export type AffixProps = AntAffixProps & {
    // Custom Props definition
}

export const Affix: FC<AffixProps> = ({ className, ...rest }) => {
    return (
        <AntAffix 
            className={`${className || ''} pyxis-affix`} 
            {...rest} 
        />
    )
}