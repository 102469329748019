import React, { FC } from 'react'
import { Collapse as AntCollapse } from 'antd/lib'
import { CollapseProps as AntCollapseProps } from 'antd/lib/collapse'

import './collapse.less'

const { Panel } = AntCollapse;

export type CollapseProps = AntCollapseProps & {
    // Custom Props definition
}

export const Collapse: FC<CollapseProps> = ({ className, ...rest }) => {
    return (
        <AntCollapse 
            className={`${className || ''} pyxis-collapse`} 
            {...rest} 
        />
    )
}

export const CollapsePanel = Panel