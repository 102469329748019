import React, { FC } from 'react'
import { Popover as AntPopover } from 'antd/lib'
import { PopoverProps as AntPopoverProps } from 'antd/lib/popover'

import './popover.less'

export type PopoverProps = AntPopoverProps & {
    // Custom Props definition
}

export const Popover: FC<PopoverProps> = ({ className, ...rest }) => {
    return (
        <AntPopover
            className={`${className || ''} pyxis-popover`} 
            {...rest} 
        />
    )
}