import React, { FC } from 'react'
import { InputNumber as AntInputNumber } from 'antd/lib'
import { InputNumberProps as AntInputNumberProps } from 'antd/lib/input-number'

import './inputnumber.less'

export type InputNumberProps = AntInputNumberProps & {
    // Custom Props definition
}

export const InputNumber: FC<InputNumberProps> = ({ className, ...rest }) => {
    return (
        <AntInputNumber
            className={`${className || ''} pyxis-inputnumber`}
            {...rest}
        />
    )
}