import React, { FC } from 'react'
import { Tree as AntTree } from 'antd/lib'
import { TreeProps as AntTreeProps } from 'antd/lib/tree'

import './tree.less'

export type TreeProps = AntTreeProps & {
    // Custom Props definition
}

export const Tree: FC<TreeProps> = ({ className, ...rest }) => {
    return (
        <AntTree 
            className={`${className || ''} pyxis-tree`} 
            {...rest} 
        />
    )
}