import React, { FC } from 'react'
import { Layout as AntLayout } from 'antd/lib'
import { LayoutProps as AntLayoutProps } from 'antd/lib/layout'

import './layout.less'

export type LayoutProps = AntLayoutProps & {
    // Custom Props definition
}

export const Layout: FC<LayoutProps> = ({ className, ...rest }) => {
    return (
        <AntLayout
            className={`${className || ''} pyxis-layout`} 
            {...rest} 
        />
    )
}

export const Sider = AntLayout.Sider

export const Header = AntLayout.Header

export const Content = AntLayout.Content

export const Footer = AntLayout.Footer