import React, { FC } from 'react'
import { Card as AntCard } from 'antd/lib'
import { CardProps as AntCardProps } from 'antd/lib/card'

import './card.less'

export type CardProps = AntCardProps & {
    // Custom Props definition
}

export const Card: FC<CardProps> = ({ className, ...rest }) => {
    return (
        <AntCard
            className={`${className || ''} pyxis-card`} 
            {...rest} 
        />
    )
}