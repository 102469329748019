import React, { FC } from 'react'
import { Badge as AntBadge } from 'antd/lib'
import { BadgeProps as AntBadgeProps } from 'antd/lib/badge'

import './badge.less'

export type BadgeProps = AntBadgeProps & {
    // Custom Props definition
}

export const Badge: FC<BadgeProps> = ({ className, ...rest }) => {
    return ( 
        <AntBadge
            className={`${className || ''} pyxis-badge`} 
            {...rest} 
        />
    )
}