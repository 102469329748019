import React, { FC } from 'react'
import { Button as AntBtn } from 'antd/lib'
import { ButtonProps as AntBtnProps } from 'antd/lib/button'

import './socialbutton.less'

import FacebookFilled from '@ant-design/icons/FacebookFilled';
import TwitterCircleFilled from '@ant-design/icons/TwitterCircleFilled';
import InstagramFilled from '@ant-design/icons/InstagramFilled';
import LinkedinFilled from '@ant-design/icons/LinkedinFilled';
import GoogleCircleFilled from '@ant-design/icons/GoogleCircleFilled';

export type SocialButtonProps = AntBtnProps & {
    // Custom Props definition
    btnsize?: 'sm' | 'md' | 'lg'
    btntype?: 'facebook' | 'twitter' | 'instagram' | 'linkedin' | 'google'
}

export const SocialButton: FC<SocialButtonProps> = ({
    className,
    btnsize = 'sm',
    btntype = 'facebook',
    ...rest
}) => {
    let logo, content
    if(btntype === 'facebook') {
        logo = <FacebookFilled />
    } else if(btntype === 'twitter') {
        logo = <TwitterCircleFilled />
    } else if(btntype === 'instagram') {
        logo = <InstagramFilled />
    } else if(btntype === 'linkedin') {
        logo = <LinkedinFilled />
    } else if(btntype === 'google') {
        logo = <GoogleCircleFilled />
    }
    if(btnsize === 'md') {
        content = btntype.charAt(0).toUpperCase() + btntype.slice(1)
    } else if(btnsize === 'lg') {
        content = 'Connect with ' + btntype.charAt(0).toUpperCase() + btntype.slice(1)
    }
    
    return (
        <AntBtn
            className={`${className || ''} pyxis-social-btn social-${btntype} social-${btnsize}`}
            {...rest}>
                <span>{logo}</span>
                <span>{content}</span>
        </AntBtn>
    )
}