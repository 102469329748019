import React, { FC } from 'react'
import { Upload as AntUpload} from 'antd/lib'
import { UploadProps as AntUploadProps } from 'antd/lib/upload'

import './draganddrop.less'

const { Dragger } = AntUpload;

export type DragAndDropProps = AntUploadProps & {
    // Custom Props definition
}

export const DragAndDrop: FC<DragAndDropProps> = ({ className, ...rest }) => {
    return ( 
        <Dragger
            className={`${className || ''} pyxis-upload`}
            {...rest}>
        </Dragger>
    )
}