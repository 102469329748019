import React, { FC } from 'react'
import { Form as AntForm } from 'antd/lib'
import { FormProps as AntFormProps } from 'antd/lib/form'

import './form.less'

export type FormProps = AntFormProps & {
    // Custom Props definition
    name?: string;
    initialValues?: any;
    layout?: any;
    onFinish?: Function;
    onFinishFailed?: Function;
}

export const Form: FC<FormProps> = ({ name, initialValues, layout, onFinish, onFinishFailed, className, ...rest }) => {
    return (
        <AntForm 
            {...layout}
            name={name}
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className={`${className || ''} pyxis-form`} {...rest} 
        />
    )
}

export const FormItem = AntForm.Item;