import React, { FC } from 'react'
import { Comment as AntComment} from 'antd/lib';
import { CommentProps as AntCommentProps } from 'antd/lib/comment'

import './comment.less'

export type CommentProps = AntCommentProps & {
    // Custom Props definition
}

export const Comment: FC<CommentProps> = ({ className, ...rest }) => {
    return (
        <AntComment 
            className={`${className || ''} pyxis-comment`} 
            {...rest} 
        />
    )
}