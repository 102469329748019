import React, { FC } from 'react'
import { Row as AntRow } from 'antd/lib'
import { RowProps as AntRowProps } from 'antd/lib/grid/row'

import './row.less'

export type RowProps = AntRowProps & {
    // Custom Props definition
}

export const Row: FC<RowProps> = ({ className, ...rest }) => {
    return (
        <AntRow
            className={`${className || ''} pyxis-row`} 
            {...rest} 
        />
    )
}