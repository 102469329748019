import React, { FC } from 'react'
import { Select as AntSelect } from 'antd/lib'
import { SelectProps as AntSelectProps } from 'antd/lib/select'

import './select.less'

const { Option } = AntSelect;

export type SelectProps = AntSelectProps<any> & {
    // Custom Props definition
}

export const Select: FC<SelectProps> = ({ className, ...rest }) => {
    return (
        <AntSelect 
            className={`${className || ''} pyxis-select`} 
            {...rest}
        />
    )
}

export const SelectOption = Option