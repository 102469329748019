import React, { FC } from 'react'
import { Dropdown as AntDropdown } from 'antd/lib'
import { DropDownProps as AntDropdownProps } from 'antd/lib/dropdown'

import './dropdown.less'

export type DropdownProps = AntDropdownProps & {
    // Custom Props definition
}

export const Dropdown: FC<DropdownProps> = ({ className, ...rest }) => {
    return (
        <AntDropdown
            className={`${className || ''} pyxis-dropdown`} 
            {...rest} 
        />
    )
}