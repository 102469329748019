import React, { FC } from 'react'
import { Timeline as AntTimeline } from 'antd/lib'
import { TimelineProps as AntTimelineProps } from 'antd/lib/timeline'

import './timeline.less'

export type TimelineProps = AntTimelineProps & {
    // Custom Props definition
}

export const Timeline: FC<TimelineProps> = ({ className, ...rest }) => {
    return (
        <AntTimeline 
            className={`${className || ''} pyxis-timeline`} 
            {...rest} 
        />
    )
}

export const TimelineItem = AntTimeline.Item