import React, { FC } from 'react'
import { TreeSelect as AntTreeSelect } from 'antd/lib'
import { TreeSelectProps as AntTreeSelectProps } from 'antd/lib/tree-select'

import './treeselect.less'

export type TreeSelectProps<T = {}> = AntTreeSelectProps<T> & {
    // Custom Props definition
}

export const TreeSelect: FC<TreeSelectProps> = ({ className, ...rest }) => {
    return (
        <AntTreeSelect
            className={`${className || ''} pyxis-treeselect`}
            {...rest}
        />
    )
}