import React, { FC } from 'react'
import { Table as AntTable } from 'antd/lib'

import './table.less'

export type TableProps = any & {
    // Custom Props definition
}

export const Table: FC<TableProps> = ({ className, ...rest }) => {
    return (
        <AntTable 
            className={`${className || ''} pyxis-table`} 
            {...rest} 
        />
    )
}