import React, { FC } from 'react'
import { Checkbox as AntCheckbox } from 'antd/lib'
import { CheckboxProps as AntCheckboxProps } from 'antd/lib/checkbox'

import './checkbox.less'

export type CheckboxProps = AntCheckboxProps & {
    // Custom Props definition
}

export const Checkbox: FC<CheckboxProps> = ({ className, ...rest }) => {
    return (
        <AntCheckbox 
            className={`${className || ''} pyxis-checkbox`} 
            {...rest} 
        />
    )
}