import React, { FC } from 'react'
import { Carousel as AntCarousel } from 'antd/lib'
import { CarouselProps as AntCarouselProps } from 'antd/lib/carousel'

import './carousel.less'

export type CarouselProps = AntCarouselProps & {
    // Custom Props definition
}

export const Carousel: FC<CarouselProps> = ({ className, ...rest }) => {
    return (
        <AntCarousel 
            className={`${className || ''} pyxis-carousel`} 
            {...rest} 
        />
    )
}