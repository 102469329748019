import React, { FC } from 'react'
import { Image as AntImage } from 'antd/lib'

import './image.less'

export type ImageProps = any & {
    // Custom Props definition
}

export const Image: FC<ImageProps> = ({className, ...rest}) => {
    return (
        <AntImage 
            className={`${className || ''} pyxis-image`} 
            {...rest}
        />
    )
}