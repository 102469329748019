import React, { FC } from 'react'
import { Steps as AntSteps } from 'antd/lib'
import { StepsProps as AntStepsProps } from 'antd/lib/steps'

import './steps.less'

export type StepsProps = AntStepsProps & {
    // Custom Props definition
}

export const Steps: FC<StepsProps> = ({ className, ...rest }) => {
    return (
        <AntSteps
            className={`${className || ''} pyxis-steps`} 
            {...rest} 
        />
    )
}

export const Step = AntSteps.Step