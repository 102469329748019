import React, { FC } from 'react'
import { Slider as AntSlider } from 'antd/lib'
import { SliderBaseProps as AntSliderProps } from 'antd/lib/slider'

import './slider.less'

export type SliderProps = AntSliderProps & {
    // Custom Props definition
}

export const Slider: FC<SliderProps> = ({ className, ...rest }) => {
    return (
        <AntSlider 
            className={`${className || ''} pyxis-slider`} 
            {...rest} 
        />
    )
}