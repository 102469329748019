import React, { FC } from 'react'
import { Pagination as AntPagination } from 'antd/lib'
import { PaginationProps as AntPaginationProps } from 'antd/lib/pagination'

import './pagination.less'

export type PaginationProps = AntPaginationProps & {
    // Custom Props definition
}

export const Pagination: FC<PaginationProps> = ({ className, ...rest }) => {
    return (
        <AntPagination 
            className={`${className || ''} pyxis-pagination`} 
            {...rest} 
        />
    )
}