import React, { FC } from 'react'
import { Breadcrumb as AntBreadcrumb } from 'antd/lib'
import {
    BreadcrumbProps as AntBreadcrumbProps,
    BreadcrumbItemProps 
} from 'antd/lib/breadcrumb'

import './breadcrumb.less'

export type BreadcrumbProps = AntBreadcrumbProps & {
    // Custom Props definition
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ className, ...rest }) => {
    return (
        <AntBreadcrumb
            className={`${className || ''} pyxis-breadcrumb`}
            {...rest}
        />
    )
}

export const BreadcrumbItem: FC<BreadcrumbItemProps> = AntBreadcrumb.Item