import React, { FC } from 'react'
import { Progress as AntProgress } from 'antd/lib'
import { ProgressProps as AntProgressProps } from 'antd/lib/progress'

import './progress.less'

export type ProgressProps = AntProgressProps & {
    // Custom Props definition
}

export const Progress: FC<ProgressProps> = ({ className, ...rest }) => {
    return (
        <AntProgress 
            className={`${className || ''} pyxis-progress`} 
            {...rest} 
        />
    )
}