import React from 'react'
import { Input as AntInput } from 'antd/lib'
import { InputProps as AntInputProps } from 'antd/lib/input'

import './input.less'

export type InputProps = AntInputProps & {
    // Custom Props definition
}

export const Input= React.forwardRef<InputProps,any>(({ className, ...rest }, ref) => {
    return (
        <AntInput 
            className={`${className || ''} pyxis-input`} 
            {...rest}
            ref={ref}
        />
    )
})

export const TextArea = AntInput.TextArea

export const Search = AntInput.Search

export const Group = AntInput.Group 