import React, { FC } from 'react'
import { Rate as AntRate } from 'antd/lib'
import { RateProps as AntRateProps } from 'antd/lib/rate'

import './rate.less'

export type RateProps = AntRateProps & {
    // Custom Props definition
}

export const Rate: FC<RateProps> = ({ className, ...rest }) => {
    return (
        <AntRate 
            className={`${className || ''} pyxis-rate`} 
            {...rest} 
        />
    )
}