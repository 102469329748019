import React, { FC } from 'react'
import { Descriptions as AntDescriptions } from 'antd/lib'
import { DescriptionsProps as AntDescriptionsProps } from 'antd/lib/descriptions'

import './descriptions.less'

export type DescriptionsProps = AntDescriptionsProps & {
    // Custom Props definition
}

export const Descriptions: FC<DescriptionsProps> = ({ className, ...rest }) => {
    return (
        <AntDescriptions 
            className={`${className || ''} pyxis-descriptions`} 
            {...rest} 
        />
    )
}

export const Item = AntDescriptions.Item;